<template>
  <button 
    v-on:click="buttonAction()" 
    type="button" 
    class="btn sam-primary-button" 
    :disabled="disabled === true" 
    :data-toggle="toggleModal ? 'modal' : '' "
    :data-target="`#${modalId}`"
  >
    {{ text }}
  </button>
</template>
<script>

export default {
  name: 'SamPrimaryButton',
  components: {},
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: ''
    },
    link: {
      type: String,
      default: ''
    },
    toggleModal: {
      type: Boolean,
      default: false
    },
    modalId: {
      type: String,
      default: ''
    }
  },
  methods: {
   buttonAction(){
      this.$emit('buttonAction');
    },
  }
}
</script>

<style lang="scss" scoped>

.btn.sam-primary-button, .btn.sam-primary-button:focus {
  background-color: $almostBlack;
  color: $white;
  border: none;
  height: $regularButtonHeight;
  width: $regularButtonWidth;
  transition: $mainTransition;
  font-family: $boldFontStyle;
  border-radius: $mainRadius;
}

.sam-primary-button:hover {
  opacity: $hoverOpacity;
}

.sam-primary-button:disabled {
  opacity: $disableOpacity;
}

</style>
